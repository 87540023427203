import { PoolConfig, QuoteToken, PoolCategory } from "./types";

const pools: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: "MAF",
    tokenAddress: "0xd7090dfb7615b0916D7925EF44Bd4e0BEeB7e84e", // token address
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: "0xd7090dfb7615b0916D7925EF44Bd4e0BEeB7e84e", // token address
    contractAddress: {
      97: "",
      56: "0x19C3f91058a38f3983Ebc90f385d17fB4964334e", /// masterchef address
    },
    poolCategory: PoolCategory.CORE,
    projectLink: "/",
    harvest: true,
    tokenPerBlock: "10",
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
  // {
  //   sousId: 4,
  //   tokenName: 'TUR',
  //   tokenAddress: '0x9E0116f55D34e7d7463E4fd9A5fF277eB3f919ae',   // token address
  //   stakingTokenName: QuoteToken.CAKE,
  //   stakingTokenAddress: '0x9E0116f55D34e7d7463E4fd9A5fF277eB3f919ae',  // token address
  //   contractAddress: {
  //     97: '',
  //     56: '0x19C3f91058a38f3983Ebc90f385d17fB4964334e',  /// masterchef address
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: '/',
  //   harvest: true,
  //   tokenPerBlock: '10',
  //   sortOrder: 1,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
];

export default pools;
