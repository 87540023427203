import React, { useMemo, useState } from "react";
import BigNumber from "bignumber.js";
import styled from "styled-components";
import { getBalanceNumber } from "utils/formatBalance";
import useI18n from "hooks/useI18n";
import { ChevronDown, ChevronUp } from "react-feather";
import {
  Text,
  Flex,
  Link,
  LinkExternal,
  MetamaskIcon,
} from "@pancakeswap-libs/uikit";
import Balance from "components/Balance";
import { CommunityTag, CoreTag, BinanceTag } from "components/Tags";
import { useBlock } from "state/hooks";
import { PoolCategory } from "config/constants/types";
import { registerToken } from "utils/wallet";
import { BASE_URL } from "config";

const tags = {
  [PoolCategory.BINANCE]: BinanceTag,
  [PoolCategory.CORE]: CoreTag,
  [PoolCategory.COMMUNITY]: CommunityTag,
};

interface Props {
  projectLink: string;
  decimals: number;
  totalStaked: BigNumber;
  tokenName: string;
  tokenAddress: string;
  tokenDecimals: number;
  startBlock: number;
  endBlock: number;
  isFinished: boolean;
  poolCategory: PoolCategory;
}

const StyledFooter = styled.div<{ isFinished: boolean }>`
  border-top: 1px solid
    ${({ theme }) => (theme.isDark ? "rgba(150, 150, 150, 0.18)" : "#E9EAEB")};
  color: ${({ isFinished, theme }) =>
    theme.colors[isFinished ? "textDisabled2" : "primary2"]};
  padding: 24px;
`;

const StyledDetailsButton = styled.button`
  width: 100%;
  align-items: center;
  background-color: transparent;
  border: 0;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  outline: 0;
  padding: 0;
  &:hover {
    opacity: 0.9;
  }

  & > svg {
    margin-left: 4px;
  }
`;

const Details = styled.div`
  margin-top: 24px;
`;

const Row = styled(Flex)`
  align-items: center;
`;

const FlexFull = styled.div`
  flex: 1;
`;
const Label = styled.div`
  font-size: 14px;
`;
const TokenLink = styled.a`
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
`;

const RowCenter = styled.div`
  display: flex;
  justify-content: center:
  align-items: center;
`;

const Wrapper = styled.div`
  margin-top: 24px;
`;

const StyledLinkExternal = styled(LinkExternal)`
  text-decoration: none;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  align-items: center;

  svg {
    padding-left: 4px;
    height: 18px;
    width: auto;
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const CardFooter: React.FC<Props> = ({
  projectLink,
  decimals,
  tokenAddress,
  totalStaked,
  tokenName,
  tokenDecimals,
  isFinished,
  startBlock,
  endBlock,
  poolCategory,
}) => {
  const { blockNumber: currentBlock } = useBlock();
  const [isOpen, setIsOpen] = useState(false);
  const TranslateString = useI18n();
  const Icon = isOpen ? ChevronUp : ChevronDown;

  const handleClick = () => setIsOpen(!isOpen);
  const Tag = tags[poolCategory];

  const blocksUntilStart = Math.max(startBlock - currentBlock, 0);
  const blocksRemaining = Math.max(endBlock - currentBlock, 0);

  const imageSrc = `${BASE_URL}/images/tokens/${tokenName.toLowerCase()}.png`;

  return (
    <StyledFooter isFinished={isFinished}>
      <RowCenter>
        <StyledDetailsButton onClick={handleClick}>
          {isOpen
            ? TranslateString(1066, "Hide")
            : TranslateString(658, "Details")}{" "}
          <Icon />
        </StyledDetailsButton>
      </RowCenter>
      {isOpen && (
        <Wrapper>
          <Flex justifyContent="space-between">
            <Text>{TranslateString(23, "Total staked")}:</Text>
            <Text>
              <Balance
                fontSize="14px"
                isDisabled={isFinished}
                value={getBalanceNumber(totalStaked, decimals)}
              />
            </Text>
          </Flex>

          <Flex justifyContent="flex-start">
            <Link external href="Lorem" bold={false}>
              {TranslateString(356, "View on BscScan")}
            </Link>
          </Flex>
        </Wrapper>

        // <Details>
        //   <Row mb="4px">
        //     <FlexFull>
        //       <Label>
        //         {/* <span role="img" aria-label="syrup">
        //           🥞{' '}
        //         </span> */}
        //         {TranslateString(408, 'Total')}
        //       </Label>
        //     </FlexFull>
        //     <Balance fontSize="14px" isDisabled={isFinished} value={getBalanceNumber(totalStaked, decimals)} />
        //   </Row>
        //   {blocksUntilStart > 0 && (
        //     <Row mb="4px">
        //       <FlexFull>
        //         <Label>{TranslateString(410, 'Start')}:</Label>
        //       </FlexFull>
        //       <Balance fontSize="14px" isDisabled={isFinished} value={blocksUntilStart} decimals={0} />
        //     </Row>
        //   )}
        //   {blocksUntilStart === 0 && blocksRemaining > 0 && (
        //     <Row mb="4px">
        //       <FlexFull>
        //         <Label>{TranslateString(410, 'End')}:</Label>
        //       </FlexFull>
        //       <Balance fontSize="14px" isDisabled={isFinished} value={blocksRemaining} decimals={0} />
        //     </Row>
        //   )}
        //   <Flex mb="4px">
        //     <TokenLink onClick={() => registerToken(tokenAddress, tokenName, tokenDecimals, imageSrc)}>
        //       Add {tokenName}
        //     <MetamaskIcon mb="-4px" height={20} width={20} ml="4px" onClick={() => registerToken(tokenAddress, tokenName, tokenDecimals, imageSrc)}/>
        //     </TokenLink>
        //   </Flex>

        //   {/* <TokenLink href={projectLink} target="_blank">
        //     {TranslateString(412, 'View project site')}
        //   </TokenLink> */}
        // </Details>
      )}
    </StyledFooter>
  );
};

export default React.memo(CardFooter);
