/* eslint-disable camelcase */
import { ethers } from "ethers";
import FarmingABI from "../abi/FarmAndPoll.json";
import IERC20Abi from "../abi/IERC20.json";
import TokenMigrationAbi from "../abi/TokenMigration.json";

const RPC_URL__MAINNET = "https://bsc-dataseed3.binance.org";
const ADDRESS_CONTRACT = "0x19C3f91058a38f3983Ebc90f385d17fB4964334e";
const PROVIDER = new ethers.providers.JsonRpcProvider(RPC_URL__MAINNET);
const GAS_LIMIT = 150000;

const Farming_Contract = new ethers.Contract(
  ADDRESS_CONTRACT,
  FarmingABI,
  PROVIDER
);

const IERC20_Contract = new ethers.Contract(
  ADDRESS_CONTRACT,
  IERC20Abi.abi,
  PROVIDER
);

// Migration ----------------------------------------------------------------
const PROVIDER__Migration = new ethers.providers.JsonRpcProvider(
  RPC_URL__MAINNET
);

const TokenMigration_ADDRESSES = {
  token1: "0x3bd460D1C7fd2A4Cf45b6c5abb206A1Ad7026685",
  token2: "0xd7090dfb7615b0916D7925EF44Bd4e0BEeB7e84e",
  tokenMigration: "0xc75e0414a4cb9705b24096ee67c0c91d9a7c6c68",
};

const TokenMigration_Contract = new ethers.Contract(
  TokenMigration_ADDRESSES.tokenMigration,
  TokenMigrationAbi.abi,
  PROVIDER__Migration
);

const TokenMigration_Token1_Contract = new ethers.Contract(
  TokenMigration_ADDRESSES.token1,
  IERC20Abi.abi,
  PROVIDER__Migration
);

export {
  Farming_Contract,
  ethers,
  GAS_LIMIT,
  IERC20_Contract,
  TokenMigration_Contract,
  TokenMigration_ADDRESSES,
  TokenMigration_Token1_Contract,
};
