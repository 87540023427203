import React from "react";
import BigNumber from "bignumber.js";
import styled from "styled-components";
import { Modal, Text, LinkExternal, Flex } from "@pancakeswap-libs/uikit";
import useI18n from "hooks/useI18n";
import {
  calculateCakeEarnedPerThousandDollars,
  apyModalRoi,
} from "utils/compoundApyHelpers";

interface ApyCalculatorModalProps {
  onDismiss?: () => void;
  lpLabel?: string;
  cakePrice?: BigNumber;
  apy?: BigNumber;
  addLiquidityUrl?: string;
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, auto);
  margin-bottom: 24px;
`;

const GridItem = styled.div`
  margin-bottom: "10px";
  padding: 10px 10px 10px 10px;
`;

const GridItem1 = styled.div`
  margin-bottom: "10px";
  background-color: rgb(0, 0, 0, 0.2);
  padding: 10px 10px 10px 10px;
  border-radius: 10px 0px 0px 10px;
`;

const GridItem2 = styled.div`
  margin-bottom: "10px";
  background-color: rgb(0, 0, 0, 0.2);
  padding: 10px 10px 10px 10px;
`;

const GridItem3 = styled.div`
  margin-bottom: "10px";
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px 10px 10px 10px;
  border-radius: 0px 10px 10px 0px;
`;

const Description = styled(Text)`
  max-width: 540px;
  text-align: center;
  margin: 0px auto 28px auto;
`;

const TokenName = styled.span`
  font-weight: bold;
`;

const ApyCalculatorModal: React.FC<ApyCalculatorModalProps> = ({
  onDismiss,
  lpLabel,
  cakePrice,
  apy,
  addLiquidityUrl,
}) => {
  const TranslateString = useI18n();
  // const farmApy = apy.times(new BigNumber(100)).toNumber()
  // const oneThousandDollarsWorthOfCake = 1000 / cakePrice.toNumber()

  // const cakeEarnedPerThousand1D = calculateCakeEarnedPerThousandDollars({ numberOfDays: 1, farmApy, cakePrice })
  // const cakeEarnedPerThousand7D = calculateCakeEarnedPerThousandDollars({ numberOfDays: 7, farmApy, cakePrice })
  // const cakeEarnedPerThousand30D = calculateCakeEarnedPerThousandDollars({ numberOfDays: 30, farmApy, cakePrice })
  // const cakeEarnedPerThousand365D = calculateCakeEarnedPerThousandDollars({ numberOfDays: 365, farmApy, cakePrice })

  return (
    <Modal title="ROI" onDismiss={onDismiss}>
      <Description fontSize="14px" color="textSubtle">
        {TranslateString(
          866,
          "Calculated based on current rates. Compounding once daily. Rates are estimates provided for your convenience only, and by no means represent guaranteed returns."
        )}
      </Description>
      {/* {/* <Grid> */}
      {/* <GridItem>
          <Text fontSize="12px" bold color="textSubtle" textTransform="uppercase" mb="5px">
            {TranslateString(860, 'Timeframe')}
          </Text>
        </GridItem>
        <GridItem>
          <Text fontSize="12px" bold color="textSubtle" textTransform="uppercase" mb="5px">
            {TranslateString(858, 'ROI')}
          </Text>
        </GridItem>
        <GridItem>
          <Text fontSize="12px" bold color="textSubtle" textTransform="uppercase" mb="5px">
            {TranslateString(864, 'MAF per $1000')}
          </Text>
        </GridItem> */}
      {/* 1 day row */}
      {/* <GridItem1>
          <Text>1d</Text>
        </GridItem1>
        <GridItem2>
          <Text>
            {apyModalRoi({ amountEarned: cakeEarnedPerThousand1D, amountInvested: oneThousandDollarsWorthOfCake }).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}%
          </Text>
        </GridItem2>
        <GridItem3>
          <Text>{cakeEarnedPerThousand1D.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} <TokenName>MAF</TokenName></Text>
        </GridItem3> */}
      {/* 7 day row */}
      {/* <GridItem>
          <Text>7d</Text>
        </GridItem>
        <GridItem>
          <Text>
            {apyModalRoi({ amountEarned: cakeEarnedPerThousand7D, amountInvested: oneThousandDollarsWorthOfCake }).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}%
          </Text>
        </GridItem>
        <GridItem>
          <Text>{cakeEarnedPerThousand7D.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} <TokenName>MAF</TokenName></Text>
        </GridItem> */}
      {/* 30 day row */}
      {/* <GridItem1>
          <Text>30d</Text>
        </GridItem1>
        <GridItem2>
          <Text>
            {apyModalRoi({ amountEarned: cakeEarnedPerThousand30D, amountInvested: oneThousandDollarsWorthOfCake }).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}%
          </Text>
        </GridItem2>
        <GridItem3>
          <Text>{cakeEarnedPerThousand30D.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} <TokenName>MAF</TokenName></Text>
        </GridItem3> */}
      {/* 365 day / APY row */}
      {/* <GridItem>
          <Text>365d</Text>
        </GridItem>
        <GridItem>
          <Text>
            {apyModalRoi({ amountEarned: cakeEarnedPerThousand365D, amountInvested: oneThousandDollarsWorthOfCake }).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}%
          </Text>
        </GridItem>
        <GridItem>
          <Text>{cakeEarnedPerThousand365D.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} <TokenName>MAF</TokenName></Text>
        </GridItem> */}
      {/* </Grid> */}
      {/* <Flex justifyContent="center"> */}
      {/* <LinkExternal href={addLiquidityUrl}>
          {TranslateString(999, 'Get')} {lpLabel}
        </LinkExternal> */}
      {/* </Flex> */}
    </Modal>
  );
};

export default ApyCalculatorModal;
