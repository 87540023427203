import { createGlobalStyle } from "styled-components";
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from "@pancakeswap-libs/uikit/dist/theme";

declare module "styled-components" {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`

  * {
    font-family: 'Kanit', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
  
  #preload img{
    width: 200px;
  }

  @-moz-keyframes blinker {  
      0% { opacity: 1.0; }
      50% { opacity: 0.2; }
      100% { opacity: 1.0; }
  }

  @-webkit-keyframes blinker {  
      0% { opacity: 1.0; }
      50% { opacity: 0.2; }
      100% { opacity: 1.0; }
  }

  @keyframes blinker {  
      0% { opacity: 1.0; }
      50% { opacity: 0.2; }
      100% { opacity: 1.0; }
  }
`;

export default GlobalStyle;
