import contracts from "./contracts";
import { FarmConfig, QuoteToken } from "./types";

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: "MAF",
    lpAddresses: {
      97: "",
      56: "0xBfB2EE613F61188A893639e5A8c297FB42bcA0E4",
    },
    tokenSymbol: "SYRUP",
    tokenAddresses: {
      97: "",
      56: "0x9E0116f55D34e7d7463E4fd9A5fF277eB3f919ae",
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 1,
    lpSymbol: "MAF-BNB LP",
    lpAddresses: {
      97: "",
      56: "0xBfB2EE613F61188A893639e5A8c297FB42bcA0E4", // lp address token-bnb
    },
    tokenSymbol: "MAF",
    tokenAddresses: {
      97: "",
      56: "0x9E0116f55D34e7d7463E4fd9A5fF277eB3f919ae", // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 2,
    lpSymbol: "TUR-MAF LP",
    lpAddresses: {
      97: "",
      56: "0xA22bd986a5451FA244BB92f88B9B62F18C9993D9", // lp address token-maf
    },
    tokenSymbol: "TUR",
    tokenAddresses: {
      97: "",
      56: "0x29A75Ec2d2B8a57fDc45094dC51feFD147c908d8", // token address
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },

  // {
  //   pid: 3,
  //   lpSymbol: 'MAF-MNC LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x3979979A7766ce88FE0ED67D394Ef5d17DD7d883',   // lp address token-maf
  //   },
  //   tokenSymbol: 'MNC',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xB8AE295FEF08728B48094D5A1D4A01F2b85139F8', // token address
  //   },
  //   quoteTokenSymbol: QuoteToken.CAKE,
  //   quoteTokenAdresses: contracts.cake,
  // },

  // {
  //   pid: 4,
  //   lpSymbol: 'TUR-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xB6Ee15e7E16f6d3F7c0C4e9bb85962C31C328C9e',   // lp address token-bnb
  //   },
  //   tokenSymbol: 'TUR',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x29A75Ec2d2B8a57fDc45094dC51feFD147c908d8', // token address
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },

  // {
  //   pid: 5,
  //   lpSymbol: 'MNC-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x877cf0fABD041a22d1Ff31254a90D3913cf981c3',   // lp address token-bnb
  //   },
  //   tokenSymbol: 'MNC',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xB8AE295FEF08728B48094D5A1D4A01F2b85139F8', // token address
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
];

export default farms;
