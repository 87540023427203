import React, { useEffect, lazy } from "react";
import { Router, Redirect, Route, Switch } from "react-router-dom";

import { ResetCSS } from "@pancakeswap-libs/uikit";
import BigNumber from "bignumber.js";
import useEagerConnect from "hooks/useEagerConnect";
import {
  useFetchPriceList,
  useFetchProfile,
  useFetchPublicData,
} from "state/hooks";
import styled from "styled-components";
import useGetDocumentTitlePrice from "./hooks/useGetDocumentTitlePrice";
import GlobalStyle from "./style/Global";
import Menu from "./components/Menu";
import SuspenseWithChunkError from "./components/SuspenseWithChunkError";
import ToastListener from "./components/ToastListener";
// import PageLoader from './components/PageLoader'
// import EasterEgg from './components/EasterEgg'
import Pools from "./views/Pools";
import GlobalCheckBullHiccupClaimStatus from "./views/Collectibles/components/GlobalCheckBullHiccupClaimStatus";
import history from "./routerHistory";

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import("./views/Home"));
const Farms = lazy(() => import("./views/Farms"));
const Lottery = lazy(() => import("./views/Lottery"));
const Referral = lazy(() => import("./views/Referral"));
const MigrationMAF = lazy(() => import("./views/MigrationMAF"));
const NotFound = lazy(() => import("./views/NotFound"));

const Help = styled.a`
  width: 124px;
  height: 60px;
  position: sticky;
  top: calc(100vh - 90px);
  display: block;
  margin-top: -60px;
  left: calc(100vw - 148px);
  z-index: 100000;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 224px;
    height: 60px;
    left: calc(100vw - 248px);
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    width: 224px;
    height: 60px;
    left: calc(100vw - 248px);
  }
`;

const NeedHelp = styled.div`
  background: url(/images/help-min.svg) no-repeat center center;
  width: 100%;
  height: 100%;
  display: block;
  &:hover {
    opacity: 0.7;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    background: url(/images/help.svg) no-repeat center center;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    background: url(/images/help.svg) no-repeat center center;
  }
`;

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

const App: React.FC = () => {
  // Monkey patch warn() because of web3 flood
  // To be removed when web3 1.3.5 is released
  useEffect(() => {
    console.warn = () => null;
  }, []);

  useEagerConnect();
  useFetchPublicData();
  useFetchProfile();
  useFetchPriceList();
  useGetDocumentTitlePrice();

  return (
    <Router history={history}>
      {/* <PageLoader /> */}
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <Help href="https://docs.mainfarm.io/ambassadors" target="_blank">
          <NeedHelp />
        </Help>
        <SuspenseWithChunkError fallback={<> </>}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/farms">
              <Farms />
            </Route>
            <Route path="/pools">
              <Pools />
            </Route>
            <Route path="/lottery">
              <Lottery />
            </Route>
            <Route path="/Referral">
              <Referral />
            </Route>
            <Route path="/MigrationMAF">
              <MigrationMAF />
            </Route>
            {/* <Route path="/ifo">
              <Ifos />
            </Route>
            <Route path="/collectibles">
              <Collectibles />
            </Route>
            <Route exact path="/teams">
              <Teams />
            </Route>
            <Route path="/teams/:id">
              <Team />
            </Route>
            <Route path="/profile">
              <Profile />
            </Route> */}
            {/* Redirect */}
            <Route path="/staking">
              <Redirect to="/pools" />
            </Route>
            <Route path="/syrup">
              <Redirect to="/pools" />
            </Route>
            {/* <Route path="/nft">
              <Redirect to="/collectibles" />
            </Route> */}
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      {/* <EasterEgg iterations={2} /> */}
      <ToastListener />
      <GlobalCheckBullHiccupClaimStatus />
    </Router>
  );
};

export default React.memo(App);
