/* eslint-disable no-debugger */
/* eslint-disable camelcase */
import { useCallback, useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
// eslint-disable-next-line camelcase
import {
  Farming_Contract,
  ethers,
  GAS_LIMIT,
  IERC20_Contract,
  TokenMigration_Contract,
  TokenMigration_Token1_Contract,
  TokenMigration_ADDRESSES,
} from "./var";

// eslint-disable-next-line import/prefer-default-export
export const useContract = () => {
  const { library, account } = useWeb3React();

  const web3Provider = useMemo(() => {
    if (account) {
      return new ethers.providers.Web3Provider(library);
    }

    return null;
  }, [account, library]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ReferralLevelFarmingReward = useCallback(
    (position, walletAddress): Promise<any> => {
      return new Promise((res, rej) => {
        Farming_Contract.referralLevelFarmingReward(walletAddress, position)
          .then((data) => res(data.toString()))
          .catch((err) => rej(err));
      });
    },
    []
  );

  const ReferralLevelStakingReward = useCallback(
    (position, walletAddress): Promise<any> => {
      return new Promise((res, rej) => {
        Farming_Contract.referralLevelStakingReward(walletAddress, position)
          .then((data) => res(data.toString()))
          .catch((err) => rej(err));
      });
    },
    []
  );

  const WithdrawReferrerFarmingReward = useCallback(
    (price) => {
      Farming_Contract.connect(
        web3Provider.getUncheckedSigner()
      ).withdrawReferrerFarmingReward({
        gasLimit: ethers.utils.hexlify(GAS_LIMIT),
        value: ethers.utils.parseEther(String(price)).toHexString(),
      });
    },
    [web3Provider]
  );

  const WithdrawReferrerStakingReward = useCallback(
    (price) => {
      Farming_Contract.connect(
        web3Provider.getUncheckedSigner()
      ).withdrawReferrerStakingReward({
        gasLimit: ethers.utils.hexlify(GAS_LIMIT),
        value: ethers.utils.parseEther(String(price)).toHexString(),
      });
    },
    [web3Provider]
  );

  const GetAllFriends = useCallback(
    (walletAddress) => Farming_Contract.getAllFriends(walletAddress),
    []
  );

  const GetActiveFarmingFriendsCount = useCallback(
    (walletAddress) =>
      Farming_Contract.getActiveFarmingFriendsCount(walletAddress),
    []
  );

  const GetActiveStakingFriendsCount = useCallback(
    (walletAddress) =>
      Farming_Contract.getActiveStakingFriendsCount(walletAddress),
    []
  );

  const TotalEarnedFarmingReward = useCallback(
    (walletAddress) =>
      Farming_Contract.totalEarnedFarmingReward(walletAddress),
    []
  );

  const TotalEarnedStakingReward = useCallback(
    (walletAddress) =>
      Farming_Contract.totalEarnedStakingReward(walletAddress),
    []
  );

  const UseInfo = useCallback(
    (walletAddress) => Farming_Contract.userInfo(0, walletAddress),
    []
  );

  const EnterStakingWithReferrer = useCallback(
    (amount, refKey) => {
      return Farming_Contract.connect(
        web3Provider.getUncheckedSigner()
      ).enterStakingWithReferrer(amount, refKey, {
        from: account,
        gasLimit: 500_000, gasPrice: 3_000_000_000
      });
    },
    [account, web3Provider]
  );

  const DepositWithReferrer = useCallback(
    (pid, value, refKey) => {
      return Farming_Contract.connect(
        web3Provider.getUncheckedSigner()
      ).enterStakingWithReferrer(pid, value, refKey, {
        from: account,
        gasLimit: 500_000, gasPrice: 3_000_000_000
      });
    },
    [account, web3Provider]
  );

  const PendingReward = useCallback((add) => {
    return Farming_Contract.pendingReward(0, add);
  }, []);

  const LeaveStaking = useCallback(
    (val) => {
      return Farming_Contract.connect(
        web3Provider.getUncheckedSigner()
      ).leaveStaking(val, { from: account, gasLimit: ethers.utils.hexlify(500_000) });
    },
    [account, web3Provider]
  );

  const PoolInfo = useCallback((pid) => {
    return Farming_Contract.poolInfo(pid);
  }, []);

  return {
    ReferralLevelFarmingReward,
    ReferralLevelStakingReward,
    WithdrawReferrerFarmingReward,
    WithdrawReferrerStakingReward,
    GetAllFriends,
    GetActiveFarmingFriendsCount,
    GetActiveStakingFriendsCount,
    TotalEarnedFarmingReward,
    TotalEarnedStakingReward,
    EnterStakingWithReferrer,
    DepositWithReferrer,
    UseInfo,
    LeaveStaking,
    PendingReward,
    PoolInfo,
  };
};

export const useContractMagrationMAF = () => {
  const { library, account } = useWeb3React();

  const web3Provider = useMemo(() => {
    if (account) {
      return new ethers.providers.Web3Provider(library);
    }

    return null;
  }, [account, library]);

  const Migration = useCallback(
    (amount) => {
      return TokenMigration_Contract.connect(
        web3Provider.getUncheckedSigner()
      ).swapTokens(amount, { from: account, gasLimit: 500_000 });
    },
    [account, web3Provider]
  );

  const Aapprove = useCallback(
    (amount) => {
      return TokenMigration_Token1_Contract.connect(
        web3Provider.getUncheckedSigner()
      ).approve(TokenMigration_ADDRESSES.tokenMigration, amount, {
        from: account, gasLimit: ethers.utils.hexlify(100000),
      });
    },
    [account, web3Provider]
  );

  const Allowance = useCallback(() => {
    return TokenMigration_Token1_Contract.allowance(
      account,
      TokenMigration_ADDRESSES.tokenMigration
    );
  }, [account]);

  const BalanceOf = useCallback(() => {
    return TokenMigration_Token1_Contract.balanceOf(account)
  }, [account]);

  return {
    Migration,
    Allowance,
    BalanceOf,
    Aapprove,
  };
};
