import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Tag, Flex, Heading, Image } from "@pancakeswap-libs/uikit";
import { CommunityTag, CoreTag } from "components/Tags";
import { useContract } from "views/Referral/utils/contract/useContract";

export interface ExpandableSectionProps {
  lpLabel?: string;
  multiplier?: string;
  isCommunityFarm?: boolean;
  farmImage?: string;
  tokenSymbol?: string;
  pid?: number;
}

const Wrapper = styled(Flex)`
  svg {
    margin-right: 4px;
  }
`;

const MultiplierTag = styled(Tag)`
  margin-left: 4px;
`;

const CardHeading: React.FC<ExpandableSectionProps> = ({
  lpLabel,
  pid,
  farmImage,
  tokenSymbol,
}) => {
  const { PoolInfo } = useContract();
  const [multiplier, setMultiplier] = useState(0);

  useEffect(() => {
    PoolInfo(pid).then((res) => {
      const _multiplier: number = +res.allocPoint.toString() / 100;
      setMultiplier(_multiplier);
    });
  }, [pid, PoolInfo]);

  return (
    <Wrapper justifyContent="space-between" alignItems="center" mb="12px">
      <Image
        src={`/images/pools/${farmImage}`}
        alt={tokenSymbol}
        width={64}
        height={64}
      />
      <Flex flexDirection="column" alignItems="flex-end">
        <Heading mb="4px">{lpLabel}</Heading>
        <Flex justifyContent="center">
          {/* {isCommunityFarm ? <CommunityTag /> : <CoreTag />} */}
          <MultiplierTag variant="secondary">{`${multiplier}x`}</MultiplierTag>
        </Flex>
      </Flex>
    </Wrapper>
  );
};

export default CardHeading;
