import BigNumber from "bignumber.js";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import {
  Button,
  IconButton,
  useModal,
  AddIcon,
  Image,
  Flex,
  Text,
} from "@pancakeswap-libs/uikit";

import { useWeb3React } from "@web3-react/core";
import UnlockButton from "components/UnlockButton";
import Label from "components/Label";
import { useERC20 } from "hooks/useContract";
import { useSousApprove } from "hooks/useApprove";
import useI18n from "hooks/useI18n";
import { useSousStake } from "hooks/useStake";
import { useSousUnstake } from "hooks/useUnstake";
import { getBalanceNumber } from "utils/formatBalance";
import { getPoolApy } from "utils/apy";
import { useSousHarvest } from "hooks/useHarvest";
import Balance from "components/Balance";
import { QuoteToken, PoolCategory } from "config/constants/types";
import { Pool } from "state/types";
import { useGetApiPrice } from "state/hooks";
import { useContract } from "views/Referral/utils/contract/useContract";
import DepositModal from "./DepositModal";
import WithdrawModal from "./WithdrawModal";
import CompoundModal from "./CompoundModal";
import CardTitle from "./CardTitle";
import Card from "./Card";
import HarvestButton from "./HarvestButton";
import CardFooter from "./CardFooter";
import ApyButton from "./_components/ApyButton";
import HarvestAction from "./_components/HarvestAction";

import HeaderCard from "./_components/CardHeading";

interface HarvestProps {
  pool: Pool;
}

const PoolCard: React.FC<HarvestProps> = ({ pool }) => {
  const {
    sousId,
    tokenName,
    tokenAddress,
    stakingTokenName,
    stakingTokenAddress,
    stakingTokenDecimals,
    projectLink,
    harvest,
    tokenDecimals,
    poolCategory,
    totalStaked,
    startBlock,
    endBlock,
    isFinished,
    userData,
    stakingLimit,
  } = pool;
  // Pools using native BNB behave differently than pools using a token
  const isBnbPool = poolCategory === PoolCategory.BINANCE;
  const TranslateString = useI18n();
  const stakingTokenContract = useERC20(stakingTokenAddress);
  const { account } = useWeb3React();
  const { onApprove } = useSousApprove(stakingTokenContract, sousId);
  const { onStake } = useSousStake(sousId, isBnbPool);
  const { onUnstake } = useSousUnstake(sousId);
  const { onReward } = useSousHarvest(sousId, isBnbPool);
  const { PendingReward } = useContract();

  // APY
  const rewardTokenPrice = useGetApiPrice(tokenName);
  const stakingTokenPrice = useGetApiPrice(stakingTokenName);
  const apy = getPoolApy(
    0.002,
    5760,
    getBalanceNumber(pool.totalStaked, stakingTokenDecimals),
    0.2
  );

  //  const apy = getPoolApy(
  //  0.002,
  //  5760,
  //  getBalanceNumber(pool.totalStaked, stakingTokenDecimals),
  //  0.2,
  //  )

  // useEffect(() => ,[])

  const [requestedApproval, setRequestedApproval] = useState(false);
  const [pendingTx, setPendingTx] = useState(false);

  const allowance = new BigNumber(userData?.allowance || 0);
  const stakingTokenBalance = new BigNumber(userData?.stakingTokenBalance || 0);
  const stakedBalance = new BigNumber(userData?.stakedBalance || 0);
  const earnings = new BigNumber(userData?.pendingReward || 0);

  const isOldSyrup = stakingTokenName === QuoteToken.SYRUP;
  const accountHasStakedBalance = stakedBalance?.toNumber() > 0;
  const needsApproval =
    !accountHasStakedBalance && !allowance.toNumber() && !isBnbPool;
  const isCardActive = isFinished && accountHasStakedBalance;

  const convertedLimit = new BigNumber(stakingLimit).multipliedBy(
    new BigNumber(10).pow(tokenDecimals)
  );
  const [onPresentDeposit] = useModal(
    <DepositModal
      max={
        stakingLimit && stakingTokenBalance.isGreaterThan(convertedLimit)
          ? convertedLimit
          : stakingTokenBalance
      }
      onConfirm={onStake}
      tokenName={
        stakingLimit
          ? `${stakingTokenName} (${stakingLimit} max)`
          : stakingTokenName
      }
      stakingTokenDecimals={stakingTokenDecimals}
    />
  );

  const [onPresentCompound] = useModal(
    <CompoundModal
      earnings={earnings}
      onConfirm={onStake}
      tokenName={stakingTokenName}
    />
  );
  const poolImage = `${pool.tokenName}-${pool.stakingTokenName}.svg`.toLocaleLowerCase();
  const [onPresentWithdraw] = useModal(
    <WithdrawModal
      max={stakedBalance}
      onConfirm={onUnstake}
      tokenName={stakingTokenName}
      stakingTokenDecimals={stakingTokenDecimals}
    />
  );

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true);
      const txHash = await onApprove();
      // user rejected tx or didn't go thru
      if (!txHash) {
        setRequestedApproval(false);
      }
    } catch (e) {
      console.error(e);
    }
  }, [onApprove, setRequestedApproval]);

  const [mafEarned, setMafEarned] = useState(0);

  const { library } = useWeb3React();

  useEffect(() => {
    if (account && library) {
      PendingReward(account).then((res) => setMafEarned(res.toString()));
    }
  }, [PendingReward, account, library]);

  return (
    <Card isActive={isCardActive} isFinished={isFinished && sousId !== 0}>
      {isFinished && sousId !== 0 && <PoolFinishedSash />}
      <div style={{ padding: "24px" }}>
        <HeaderCard
          pid={sousId}
          lpLabel={`Staking ${tokenName}`}
          farmImage={poolImage}
        />

        <Flex justifyContent="space-between" alignItems="center">
          <Text>{TranslateString(736, "APR")}:</Text>
          <Text bold style={{ display: "flex", alignItems: "center" }}>
            <ApyButton />
            <Balance
              fontSize="14px"
              isDisabled={isFinished}
              value={apy}
              decimals={2}
              unit="%"
            />
          </Text>
        </Flex>

        <div style={{ marginBottom: "10px" }}>
          <Flex>
            <Text
              bold
              textTransform="uppercase"
              color="secondary"
              fontSize="12px"
              pr="3px"
            >
              MAF
            </Text>
            <Text
              bold
              textTransform="uppercase"
              color="textSubtle"
              fontSize="12px"
            >
              {TranslateString(1072, "Earned")}
            </Text>
          </Flex>

          <Balance fontSize="14px" value={mafEarned} decimals={2} />
        </div>

        <Flex>
          <Text
            bold
            textTransform="uppercase"
            color="textSubtle"
            fontSize="12px"
          >
            {TranslateString(1072, "STAKED")}
          </Text>

          <Text
            style={{ marginLeft: "5px" }}
            bold
            textTransform="uppercase"
            color="textSubtle"
            fontSize="12px"
          >
            {stakingTokenName}
          </Text>
        </Flex>

        <Balance
          fontSize="14px"
          value={getBalanceNumber(stakedBalance, stakingTokenDecimals)}
          decimals={2}
        />

        <StyledCardActions>
          {!account && <UnlockButton />}
          {account &&
            (needsApproval && !isOldSyrup ? (
              <div style={{ flex: 1 }}>
                <Button
                  disabled={isFinished || requestedApproval}
                  onClick={handleApprove}
                  width="100%"
                >
                  {`Approve ${stakingTokenName}`}
                </Button>
              </div>
            ) : (
              <>
                <Button
                  disabled={stakedBalance.eq(new BigNumber(0)) || pendingTx}
                  onClick={
                    isOldSyrup
                      ? async () => {
                          setPendingTx(true);
                          await onUnstake("0", stakingTokenDecimals);
                          setPendingTx(false);
                        }
                      : onPresentWithdraw
                  }
                >
                  {`Unstake ${stakingTokenName}`}
                </Button>
                <StyledActionSpacer />
                {!isOldSyrup && (
                  <IconButton
                    disabled={isFinished && sousId !== 0}
                    onClick={onPresentDeposit}
                  >
                    <AddIcon color="white" />
                  </IconButton>
                )}
              </>
            ))}
        </StyledCardActions>
      </div>
      <CardFooter
        projectLink={projectLink}
        decimals={stakingTokenDecimals}
        totalStaked={totalStaked}
        startBlock={startBlock}
        endBlock={endBlock}
        isFinished={isFinished}
        poolCategory={poolCategory}
        tokenName={tokenName}
        tokenAddress={tokenAddress}
        tokenDecimals={tokenDecimals}
      />
    </Card>
  );
};

const PoolFinishedSash = styled.div`
  background-image: url("/images/pool-finished-sash.svg");
  background-position: top right;
  background-repeat: not-repeat;
  height: 135px;
  position: absolute;
  right: -24px;
  top: -24px;
  width: 135px;
`;

const StyledCardActions = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0;
  width: 100%;
  box-sizing: border-box;
`;

const BalanceAndCompound = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

const StyledActionSpacer = styled.div`
  height: ${(props) => props.theme.spacing[4]}px;
  width: ${(props) => props.theme.spacing[4]}px;
`;

const StyledDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`;

export default PoolCard;
