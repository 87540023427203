export default {
  cake: {
    97: "",
    56: "0xd7090dfb7615b0916D7925EF44Bd4e0BEeB7e84e",
  },
  syrup: {
    97: "",
    56: "0xe5eBeF759b699110e99301E214d964879330DDFe",
  },
  masterChef: {
    97: "",
    56: "0x19C3f91058a38f3983Ebc90f385d17fB4964334e",
  },
  sousChef: {
    97: "",
    56: "0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95",
  },
  wbnb: {
    97: "",
    56: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  },
  lottery: {
    97: "",
    56: "0x3C3f2049cc17C136a604bE23cF7E42745edf3b91",
  },
  lotteryNFT: {
    97: "",
    56: "0x5e74094Cd416f55179DBd0E45b1a8ED030e396A1",
  },
  mulltiCall: {
    56: "0x1ee38d535d541c55c9dae27b12edf090c608e6fb",
    97: "",
  },
  busd: {
    56: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    97: "",
  },
  ust: {
    56: "0x23396cF899Ca06c4472205fC903bDB4de249D6fC",
    97: "",
  },
  pancakeProfile: {
    56: "0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a",
    97: "",
  },
  pancakeRabbits: {
    56: "0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07",
    97: "",
  },
  bunnyFactory: {
    56: "0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf",
    97: "",
  },
  eth: {
    56: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
    97: "",
  },
  claimRefund: {
    56: "0xE7e53A7e9E3Cf6b840f167eF69519175c497e149",
    97: "",
  },
  pointCenterIfo: {
    56: "0x3C6919b132462C1FEc572c6300E83191f4F0012a",
    97: "",
  },
  bunnySpecial: {
    56: "0xFee8A195570a18461146F401d6033f5ab3380849",
    97: "",
  },
};
